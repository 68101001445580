<template>
  <div>
    <h1>Editar <span v-if="show">{{ state.initials }}</span></h1>
    <div>
      <pre style="display:none">{{ state | pretty_json }}</pre>
    </div>
    <div class="container">
      <FormStates
        :enviar="enviar"
        :estado="estado"
        :show="show"
        :saving="saving"
        :isReadOnly="true"
        />
      <b-card class="mt-3" header="Form Data Result" v-if="debug">
        <pre class="m-0">{{ state }}</pre>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
//import FormStates from '../components/FormStates';
import FormStates from "@/modules/states/components/FormStates";
export default {
  components: {FormStates},
  data() {
    return {
      debug:false,
      show: false,
      saving:false,
      estado:{}
    }
  },
  computed: {
    ...mapState("states", ["state"]),

  },
  methods: {
    ...mapActions("states", ["ActionShow","ActionUpdate"]),
    async enviar(event) {
      event.preventDefault();
      this.saving = true;
      try {
        await this.ActionUpdate(this.estado).then(() => {
          this.saving = false
        });
      }catch (e){
        if(e.status==400){
          alert("Problemas ao editar o estado. \nMensagem: "+e.body.message);
        }
      }

    },
    onReset(event) {
      event.preventDefault()
      // Reset our form values
    },
  },


  async created() {
    await this.ActionShow({id: this.$route.params.id}).then(() => {
      this.show = true;
      this.estado = this.state
    });
    if(this.$route.query.debug==1){
      this.debug = true;
    }
  },
};
</script>

<style>
.row{
  margin:10px auto 10px auto;
}
</style>
