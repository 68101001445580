<template>
  <div>
    <b-form
      v-if="show"
      v-on:submit="enviar"
    >
    <b-form-group
      id="input-group-1"
      label="Sigla UF:"
      label-for="initials"
      description="UF"
    >
      <b-form-input
        id="initials"
        name="initials"
        v-model="estado.initials"
        type="text"
        maxlength="2"
        placeholder="Entre com a sigla do estado"
        v-bind:readonly="isReadOnly"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="input-group-2"
      label="Nome Estado:"
      label-for="name"
      description="Ex.:São Paulo">
      <b-form-input
        id="name"
        v-model="estado.name"
        placeholder="Entre com o nome"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="input-group-2"
      label="Codigo IBGE:"
      label-for="codigo_ibge"
      description="Ex.:11">
      <b-form-input
        id="ibge_id"
        name="ibge_id"
        v-model="estado.ibge_id"
        placeholder="Entre com o codigo do IBGE"
        required
      ></b-form-input>
    </b-form-group>
      <div class="row">
        <b-spinner
          label="Loading..."
          variant="success"
          v-if="saving"
        ></b-spinner>
      </div>
      <b-button
        type="submit"
        variant="primary"
      >Salvar</b-button>
      <b-button
        type="button"
        variant="danger"
        :to="{ name: 'states_routes' }"
      >Cancelar</b-button>
    </b-form>
  </div>
</template>
<script>
export default {
  props: ['show','estado','enviar','saving','isReadOnly']

}
</script>
